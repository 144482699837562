import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/layout";
import Seo from "../../components/layout/seo";
import logo from "../../images/logo.png";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Page not found | Custom Surgical" />
      <div
        style={{
          maxWidth: "100%",
          paddingRight: "20px",
          paddingLeft: "20px",
          display: "flex",
          flexDirection: "column",
          marginTop: "5em",
          alignItems: "center",
        }}
      >
        <h1 className="h1_error">Ooops!</h1>

        <h1>404 - PAGE NOT FOUND</h1>
        <p>
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
